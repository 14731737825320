<template>
  <div class="container">
    <template v-if="user">
      <h5 class="profile-header">Ficha del Estudiante</h5>
      <div class="d-flex m-2 w-100 card-container">
        <div class="flex-col m-3 mt-4">
          <template v-if="user.image_id">
            <ImageEditor
              :image_id="user.image_id"
              :allows_crud="allows_crud"
            ></ImageEditor>
          </template>
          <b-button
            v-if="is_owner"
            @click="$bvModal.show('modal-select-image')"
            size="sm"
            class="mt-2"
            >{{ user.image_id ? "Cambiar" : "Seleccionar" }}</b-button
          >
        </div>
        <div class="flex-col m-1 personal-info">
          <div class="font-weight-bold">APELLIDOS</div>
          <div v-if="allows_crud">
            <b-form-input v-model="user.last_name" size="sm"></b-form-input>
          </div>
          <div v-else class="profile-label">
            {{ user.last_name ? user.last_name : "-" }}
          </div>
          <div class="font-weight-bold">NOMBRES</div>
          <div v-if="allows_crud">
            <b-form-input v-model="user.first_name" size="sm"></b-form-input>
          </div>
          <div v-else class="profile-label">
            {{ user.first_name ? user.first_name : "-" }}
          </div>
          <div class="font-weight-bold">RUT</div>
          <div v-if="allows_crud">
            <b-form-input v-model="user.rut" size="sm"></b-form-input>
          </div>
          <div v-else class="profile-label">
            {{ user.rut ? user.rut : "-" }}
          </div>
          <div class="font-weight-bold">CORREO</div>
          <div v-if="allows_crud">
            <b-form-input
              v-model="user.email"
              size="sm"
              type="email"
            ></b-form-input>
          </div>
          <div v-else class="profile-label">
            {{ user.email ? user.email : "" }}
          </div>
          <div class="font-weight-bold">GÉNERO</div>
          <div v-if="allows_crud">
            <b-form-select
              v-model="user.gender"
              size="sm"
              :options="genders"
              value-field="id"
              text-field="value"
            ></b-form-select>
          </div>
          <div v-else class="profile-label">
            {{ user.gender ? genders[user.gender].value : "-" }}
          </div>
          <div class="font-weight-bold">FECHA DE NACIMIENTO</div>
          <div v-if="allows_crud">
            <b-form-datepicker
              v-model="user.birth_date"
              size="sm"
              placeholder="Seleccione una fecha"
            ></b-form-datepicker>
          </div>
          <div v-else class="profile-label">
            {{ user.birth_date ? user.birth_date : "-" }}
          </div>
        </div>

        <div v-if="user.is_student" class="flex-col m-1 scholar-info">
          <div class="font-weight-bold">COHORTE</div>
          <div v-if="allows_crud">
            <b-form-input
              v-model="$v.student_card.cohort.$model"
              size="sm"
              type="number"
              :state="validateState('cohort')"
              aria-describedby="input-cohort-feedback"
            ></b-form-input>
            <b-form-invalid-feedback id="input-cohort-feedback"
              >Seleccione un año correcto.</b-form-invalid-feedback
            >
          </div>
          <div v-else class="profile-label">
            {{ student_card.cohort ? student_card.cohort : "-" }}
          </div>
          <div class="font-weight-bold">NOTA ENSEÑANZA MEDIA</div>
          <div v-if="allows_crud">
            <b-form-input
              v-model="student_card.high_school_grade"
              type="number"
              size="sm"
            ></b-form-input>
          </div>
          <div v-else class="profile-label">
            {{
              student_card.high_school_grade
                ? student_card.high_school_grade
                : "-"
            }}
          </div>
          <div class="font-weight-bold">PROMEDIO PSU</div>
          <div v-if="allows_crud">
            <b-form-input
              v-model="student_card.psu_average"
              type="number"
              size="sm"
            ></b-form-input>
          </div>
          <div v-else class="profile-label">
            {{ student_card.psu_average ? student_card.psu_average : "-" }}
          </div>
          <div class="font-weight-bold">AÑO PSU</div>
          <div v-if="allows_crud">
            <b-form-input
              v-model="$v.student_card.psu_year.$model"
              size="sm"
              type="number"
              :state="validateState('psu_year')"
              aria-describedby="input-psu_year-feedback"
            ></b-form-input>
            <b-form-invalid-feedback id="input-psu_year-feedback"
              >Seleccione un año correcto.</b-form-invalid-feedback
            >
          </div>
          <div v-else class="profile-label">
            {{ student_card.psu_year ? student_card.psu_year : "-" }}
          </div>
        </div>

        <div class="flex-col m-1 scholar-info">
          <div class="font-weight-bold">
            {{ $getVisibleNames("mesh.campus", false, "Sede").toUpperCase() }}
          </div>
          <div v-if="allows_crud">
            <b-form-select
              v-model="student_card.campus"
              size="sm"
              :options="campuses"
              value-field="id"
              text-field="name"
            ></b-form-select>
          </div>
          <div v-else class="profile-label">{{ campus ? campus : "-" }}</div>
          <div class="font-weight-bold">
            {{
              $getVisibleNames("mesh.faculty", false, "FACULTAD").toUpperCase()
            }}
          </div>
          <div v-if="allows_crud">
            <b-form-select
              v-model="student_card.faculty"
              size="sm"
              :options="filteredFaculties"
              value-field="id"
              text-field="name"
            ></b-form-select>
          </div>
          <div v-else class="profile-label">{{ faculty ? faculty : "-" }}</div>
          <div class="font-weight-bold">
            {{ $getVisibleNames("mesh.career", false, "Programa") }}
          </div>
          <div v-if="allows_crud">
            <b-form-select
              v-model="student_card.career"
              size="sm"
              :options="filteredCareers"
              value-field="id"
              text-field="name"
            ></b-form-select>
          </div>
          <div v-else class="profile-label">{{ career ? career : "-" }}</div>
          <div class="font-weight-bold">
            {{
              $getVisibleNames("mesh.egressprofile", false, "Perfil de Egreso")
            }}
            <!-- PERFIL DE EGRESO -->
          </div>
          <div v-if="allows_crud">
            <b-form-select
              v-model="student_card.egress_profile"
              size="sm"
              :options="filteredEgressProfiles"
              value-field="id"
              text-field="academic_degree"
            ></b-form-select>
          </div>
          <div v-else class="profile-label">
            {{ egressProfile ? egressProfile : "-" }}
          </div>
          <div class="font-weight-bold">PERFIL DE INGRESO</div>
          <div v-if="allows_crud">
            <b-form-select
              v-model="student_card.admission_profile"
              size="sm"
              :options="admission_profiles"
              value-field="id"
              text-field="name"
            ></b-form-select>
          </div>
          <div v-else class="profile-label">
            {{ admissionProfileName ? admissionProfileName : "-" }}
          </div>
        </div>
      </div>
      <div v-if="allows_crud">
        <b-button size="sm" @click="save">Guardar</b-button>
      </div>
      <div v-if="admissionProfile">
        <template v-if="student_card.admission_profile_finished"></template>
        <template v-else class="profile-label">
          <h4>Todavía no ha completado su Perfil de Ingreso</h4>
          <b-button size="sm" :to="{ name: 'AdmissionProfileView' }"
            >Completar Perfil de Ingreso</b-button
          >
        </template>
      </div>
      <div>
        <b-tabs content-class="mt-3">
          <!-- <b-tab title="Mis Notas" active>
            <StudentEvaluationComponent></StudentEvaluationComponent>
          </b-tab> -->
          <b-tab title="Evaluación de Desarrollo">
            <SAD :User="user"></SAD>
          </b-tab>
          <b-tab title="Si me conocen, mejor">
            <Social :User="user"></Social>
          </b-tab>
        </b-tabs>
      </div>
    </template>
    <!-- Modals -->
    <b-modal
      id="modal-select-image"
      title="Seleccionar Imagen"
      hide-footer
      size="md"
    >
      <ImageSelector @created="slotImageCreated"></ImageSelector>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import * as names from "@/store/names";
import { generateUniqueId, toast } from "@/utils/utils";
import { validationMixin } from "vuelidate";
import { minValue, maxValue } from "vuelidate/lib/validators";

export default {
  name: "StudentCardComponent",
  mixins: [validationMixin],
  props: {
    User: {
      type: Object,
      required: true,
    },
  },
  components: {
    // StudentEvaluationComponent: () =>
    //   import("../StudentCard/StudentEvaluationComponent"),
    ImageEditor: () => import("@/components/reusable/ImageEditor"),
    ImageSelector: () => import("@/components/reusable/ImageSelector"),
    SAD: () => import("../SAD"),
    Social: () => import("../Social"),
  },
  data() {
    return {
      user: this.User,
      student_card: {
        id: generateUniqueId(),
        user: this.User.id,
        cohort: null,
        high_school_grade: null,
        psu_average: null,
        psu_year: null,
        campus: null,
        career: null,
        egress_profile: null,
        faculty: null,
        shift: null,
        admission_profile: null,
        admission_profile_finished: false,
      },
      genders: [
        { id: 0, value: "Masculino" },
        { id: 1, value: "Femenino" },
        { id: 2, value: "Otro" },
      ],
      admission_profiles: [],
      allows_crud: false,
      is_owner: false,
    };
  },
  validations() {
    return {
      student_card: {
        cohort: { minValue: minValue(1990), maxValue: maxValue(2100) },
        psu_year: { minValue: minValue(1990), maxValue: maxValue(2100) },
      },
    };
  },
  computed: {
    ...mapGetters({
      campuses: names.CAMPUSES,
      faculties: names.FACULTIES,
      careers: names.CAREERS,
      egressProfiles: names.EGRESS_PROFILES,
      shifts: names.SHIFTS,
      loged_user: "getUser",
    }),
    campus() {
      const campus = this.campuses.find(
        (x) => x.id == this.student_card.campus
      );
      return campus ? campus.name : "-";
    },
    faculty() {
      const faculty = this.faculties.find(
        (x) => x.id == this.student_card.faculty
      );
      return faculty ? faculty.name : "-";
    },
    filteredFaculties() {
      let list = [];
      if (this.student_card.campus) {
        this.faculties.forEach((faculty) => {
          if (faculty.campuses.find((x) => x == this.student_card.campus))
            list.push(faculty);
        });
        return list;
      }
      return this.faculties;
    },
    career() {
      const career = this.careers.find((x) => x.id == this.student_card.career);
      return career ? career.name : "-";
    },
    filteredCareers() {
      if (this.student_card.faculty)
        return this.careers.filter(
          (x) => x.faculty == this.student_card.faculty
        );
      return this.careers;
    },
    egressProfile() {
      const egress_profile = this.egressProfiles.find(
        (x) => x.id == this.student_card.egress_profile
      );
      return egress_profile ? egress_profile.name : "-";
    },
    filteredEgressProfiles() {
      if (this.student_card.career)
        return this.egressProfiles.filter(
          (x) => x.career == this.student_card.career
        );
      return this.egressProfiles;
    },
    shift() {
      const shift = this.shifts.find((x) => x.id == this.student_card.shift);
      return shift ? shift.name : "-";
    },
    admissionProfile() {
      if (this.student_card.admission_profile) {
        const admission_profile = this.admission_profiles.find(
          (x) => x.id == this.student_card.admission_profile
        );
        if (admission_profile) return admission_profile;
      }
      return null;
    },
    admissionProfileName() {
      if (this.admissionProfile) return this.admissionProfile.name;
      return "-";
    },
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.student_card[name];
      return $dirty ? !$error : null;
    },
    save() {
      this.$v.student_card.$touch();
      if (this.$v.student_card.$anyError) {
        return;
      }
      if (isNaN(this.student_card.id)) this.createStudentCard();
      else this.updateStudentCard();
    },
    createStudentCard() {
      this.$restful
        .Post(`/cideu/student-card/`, this.student_card)
        .then((response) => {
          this.student_card = response;
          toast("Perfil creado.");
        });
    },
    updateStudentCard() {
      this.$restful
        .Put(`/cideu/student-card/${this.student_card.id}/`, this.student_card)
        .then((response) => {
          this.student_card = response;
          toast("Perfil actualizado.");
        });
    },
    slotImageCreated(image) {
      const item = {
        image_id: image.id,
      };
      if (this.user.image_id) {
        try {
          const image_id = this.user.image_id;
          this.patchUser(item);
          this.$store.dispatch(names.DELETE_IMAGE, image_id);
        } catch (error) {
          this.patchUser(item);
        }
      } else {
        this.patchUser(item);
      }
    },
    patchUser(item) {
      const payload = {
        user_id: this.user.id,
        item: item,
      };
      this.$store.dispatch(names.PATCH_USER, payload).then((response) => {
        this.user = response;
        toast("Usuario actualizado.");
      });
      this.$bvModal.hide("modal-select-image");
    },
    fetchStudentCard() {
      this.$restful
        .Get(`/cideu/student-card/?user=${this.user.id}`)
        .then((response) => {
          if (response.length > 0) {
            this.student_card = response[0];
            if (this.student_card.campus)
              this.$store.dispatch(
                names.FETCH_CAMPUSES,
                this.student_card.campus
              );
            if (this.student_card.faculty)
              this.$store.dispatch(
                names.FETCH_FACULTY,
                this.student_card.faculty
              );
            if (this.student_card.career)
              this.$store.dispatch(
                names.FETCH_CAREER,
                this.student_card.career
              );
            if (this.student_card.egress_profile)
              this.$store.dispatch(
                names.FETCH_EGRESS_PROFILE,
                this.student_card.egress_profile
              );
            if (this.student_card.shift)
              this.$store.dispatch(names.FETCH_SHIFT, this.student_card.shift);
          }
        });
    },
    fetchAdmissionProfiles() {
      const institution_id = this.$store.getters.getInstitutionId;
      if (!institution_id) return;
      this.$restful
        .Get(`/cideu/admission-profile/?institution=${institution_id}`)
        .then((response) => {
          this.admission_profiles = response;
        });
    },
  },
  created() {
    console.log(this.User.image_id, this.user.image_id);
    if (this.loged_user.is_superuser) {
      this.allows_crud = true;
      this.$store.dispatch(names.FETCH_CAMPUSES);
      this.$store.dispatch(names.FETCH_CAREERS);
      this.$store.dispatch(names.FETCH_EGRESS_PROFILES);
      this.$store.dispatch(names.FETCH_FACULTIES);
      this.$store.dispatch(names.FETCH_SHIFTS);
    }
    if (this.loged_user.id == this.user.id) this.is_owner = true;
    this.fetchStudentCard();
    this.fetchAdmissionProfiles();
  },
};
</script>

<style scoped>
.card-container {
  background-color: var(--primary-color);
  box-shadow: 1px 1px 7px #9cb0b1;
  border-radius: 5px;
  padding: 1.5rem 1rem;
}
.profile-header {
  font-weight: bold;
  border-bottom: 0.1px solid #7ebacc;
  width: 30rem;
  margin-left: calc(30% - 8px);
  margin-bottom: 1%;
}
.profile-label {
  background: #ededed;
  border-radius: 6px;
}
.personal-info,
.scholar-info {
  width: 33%;
}
.scholar-info {
  margin-left: 1rem !important;
}
@media (max-width: 1000px) {
  .profile-header {
    margin-left: calc(20% - 8px);
  }
}
@media (max-width: 800px) {
  .profile-header {
    margin-left: calc(10% - 8px);
  }
}
</style>